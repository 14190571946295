const openModalButtons = document.querySelectorAll('.open-modal-button');
const closeModalButtons = document.querySelectorAll('.close-modal-button');
const modals = document.querySelectorAll('.modal');
const body = document.querySelector('body');

openModalButtons.forEach((button) => {
  button.addEventListener('click', () => {
    const target = document.querySelector(button.dataset.modalTarget);
    target.showModal();
    body.classList.add('modal-open');
  });
});

closeModalButtons.forEach((button) => {
  button.addEventListener('click', () => {
    const modal = button.closest('.modal');
    modal.close();
    body.classList.remove('modal-open');
  });
});

modals.forEach((modal) => {
  modal.addEventListener('click', (event) => {
    if (event.target === modal) {
      modal.close();
      body.classList.remove('modal-open');
    }
  });
});