import Tab from '../../plugins/tab.js'
import Accordion from '../../plugins/accordion.js'

const elements = document.querySelectorAll('.js-tab')
for (const element of elements) {
    new Tab(element, {
        defaultOpenPanel: 0,
    })
}

const disasterSlide = document.querySelectorAll('.js-disaster-tab')
if (window.matchMedia('(min-width: 1024px)').matches) {
    for (const element of disasterSlide) {
        new Tab(element, {
            defaultOpenPanel: 0,
        })
    }
} else {
    for (const element of disasterSlide) {
        let accordion2 = new Accordion(element, {
            defaultOpenPanels: [0],
            multipleOpen: true,
            onOpen: function (trigger, panel) {
                let headerHeight = document.querySelector('header').offsetHeight
                const triggerOffset = window.scrollY + trigger.getBoundingClientRect().top - headerHeight
                window.scrollTo({
                    top: triggerOffset,
                    behavior: 'smooth',
                })
            },
        })
    }
}
