let headerHeight = document.querySelector('header').offsetHeight
let links = document.querySelectorAll('a[href^="#"]')

const hash = location.hash
/* hashからクエリ文字列を排除 */
const hashSp = hash.split('?')[0];
if (hashSp) {
    const target = document.querySelector(hashSp)
    if (target) {
        // console.log(target)
        let position = window.scrollY + target.getBoundingClientRect().top - headerHeight
        window.scrollTo({
            top: position,
            behavior: 'smooth',
        })
    }
} else if (location.href.includes('#')) {
    const target = document.querySelector('body')
    let position = window.scrollY + target.getBoundingClientRect().top - headerHeight
    window.scrollTo({
        top: position,
        behavior: 'smooth',
    })
}

links.forEach(function (link) {
    link.addEventListener('click', function (e) {
        e.preventDefault()

        let href = this.getAttribute('href')
        if (href === '#') {
            href = 'body'
            location.hash = ''
        }
        let target = document.querySelector(href)

        if (target) {
            let position = window.scrollY + target.getBoundingClientRect().top - headerHeight
            window.scrollTo({
                top: position,
                behavior: 'smooth',
            })
        }
    })
})

// let hash = location.hash;

// if ($(hash).length > 0) {
//   $(window).scrollTop(0);
//   setTimeout(function () {
//     scroll.animateScroll(document.querySelector(target), options);
//   }, 700);
// }
