const top = document.querySelector('.top-mv')

if (top) {
    const topIndexInit = () => {
        const topIndex = document.querySelector('.top-index')
        if (window.matchMedia('not all and (min-width: 1024px)').matches) {
            topIndex.classList.remove('is-visible')
            return
        }
    }

    // topIndexInit()

    // window.addEventListener('resize', () => {
    //     topIndexInit()
    // })
}

// if (top && window.matchMedia('not all and (min-width: 1024px)').matches) {
//     // 関東大震災から１００年
//     const root = document.querySelector('.top-since')
//     const slider = document.querySelector('.top-since-bg__slide-img')
//     const options = {
//         root: null,
//         rootMargin: '0px',
//         threshold: 0.5,
//     }
//     const callback = (entries, object) => {
//         entries.forEach((entry) => {
//             if (entry.isIntersecting) {
//                 slider.classList.add('--is-slide')
//                 object.unobserve(entry.target)
//             }
//         })
//     }
//     const observer = new IntersectionObserver(callback, options)
//     observer.observe(root)
// }

const topSpIndex = document.querySelector('.top-sp-index')
if (topSpIndex) {
    const topSpIndex = () => {
        const topSpIndex = document.querySelector('.top-sp-index')
        const heroNav = topSpIndex.querySelector('.c-lower-hero-nav')
        const offset = topSpIndex.getBoundingClientRect()

        const allHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        )
        const mostBottom = allHeight - window.innerHeight
        const scrollTop = window.scrollY || document.documentElement.scrollTop

        if (offset.bottom + 24 < 0 && scrollTop < mostBottom && window.matchMedia('not all and (min-width: 1024px)').matches) {
            heroNav.classList.add('--is-fixed')
            topSpIndex.style.height = `${offset.height}px`
        } else {
            heroNav.classList.remove('--is-fixed')
        }
    }

    window.addEventListener('scroll', topSpIndex)
    window.addEventListener('load', topSpIndex)
}

const lowerIndex = document.querySelector('.top-index.is-lower')

if (lowerIndex) {
    const lowerIndexTopIndexLink = lowerIndex.querySelectorAll('.top-index-link')
    const headings = Array.from(lowerIndexTopIndexLink).map((link) => {
        const href = link.getAttribute('href')
        const heading = document.querySelector(href)
        return heading
    })

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    }
    const callback = (entries, object) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const target = entry.target
                const href = `#${target.id}`
                const link = document.querySelector(`.top-index-link[href="${href}"]`)
                const links = document.querySelectorAll('.top-index-link')
                links.forEach((link) => {
                    link.classList.remove('is-active')
                })
                link.classList.add('is-active')
            }
        })
    }

    headings.forEach((heading) => {
        const observer = new IntersectionObserver(callback, options)
        observer.observe(heading)
    })
}

/* header用処理 */
const currentPage = document.querySelector('.l-main[data-current-page]');
if(currentPage) {
    const pageName = currentPage.dataset.currentPage;
    const headerItem = document.querySelector(`.l-header-nav-list__item[data-page-type="${pageName}"]`);

    if(headerItem) {
        headerItem.classList.add('is-current');
    }
}
