const toJaLink = document.querySelectorAll('.l-header-sub__link.--lang-ja, .l-header-sub-sp__link.--lang-ja')
const toEnLink = document.querySelectorAll('.l-header-sub__link.--lang-en, .l-header-sub-sp__link.--lang-en')

const jaUrl = document.body.dataset.jaUrl
const enUrl = document.body.dataset.enUrl

toJaLink.forEach((link) => {
    if (jaUrl) {
        link.setAttribute('href', jaUrl)
    }
})

toEnLink.forEach((link) => {
    if (enUrl) {
        link.setAttribute('href', enUrl)
    }
})
