import Simplemodal from '../../plugins/simplemodal.js'

const modal = new Simplemodal({
    trigger: 'data-simplemodal-trigger',
    clickOutSideClose: true,
    backFixed: false,
})

modal.options.triggers.forEach((trigger) => {
    trigger.addEventListener('mouseover', (e) => {
        e.target.click();
    })
})
